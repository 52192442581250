import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Layout from "../layouts/index";
import Img from "gatsby-image";

export default () => (
  <StaticQuery
    query={graphql`
      query CatalogueQuery {
        products: allDatoCmsProduct {
          edges {
            node {
              id
              name
              price
              descriptionNode {
                childMarkdownRemark {
                  html
                }
              }
              image {
                url
                sizes(maxWidth: 300, imgixParams: { fm: "jpg" }) {
                  ...GatsbyDatoCmsSizes
                }
              }
            }
          }
        }
        site {
          siteMetadata {
            siteName
          }
        }
      }
    `}
    render={(data) => (
      <Layout site={data.site} title="Shop">
        <div className="catalogue">
          {data.products.edges.map(({ node: product }) => (
            <div key={product.id} className="card snipcart-add-item">
              <div className="card-image">
                <Img sizes={product.image.sizes} className="img-responsive" />
              </div>
              <div class="card-header">
                <div class="card-title h5 text-bold">{product.name}</div>
                <div class="card-subtitle text-gray">{product.price}</div>
              </div>
              <div
                class="card-body"
                dangerouslySetInnerHTML={{
                  __html: product.descriptionNode.childMarkdownRemark.html,
                }}
              />
              <div class="card-footer">
                {/* <button
                  class="btn btn-primary"
                  data-item-id={product.id}
                  data-item-price={product.price}
                  data-item-image={product.image.url}
                  data-item-name={product.name}
                  data-item-url={`/`}
                >
                  Add to cart
                </button> */}
              </div>
            </div>
          ))}
        </div>
      </Layout>
    )}
  />
);
